// Headroom

(function() {
    var nav = document.querySelector(".header");
    var bannerHeight = $(".hero-banner").height();

    new Headroom(nav, {
        tolerance: {
          down : 0,
          up : 20
        },
        offset : bannerHeight,
        classes: {
          initial: "header",
          pinned: "",
          unpinned: "",
          top: "header--top",
          notTop: "header--not-top"
        }
    }).init();

}());

// Hidden toggle menu when click outside

$(".menu-toggle").click(function () {
    $(".nav").toggleClass("nav--active");
    $(".menu-toggle").toggleClass("menu-toggle--active");
    $(document.body).mousedown(function(event) {
        var target = $(event.target);
        if (!target.parents().andSelf().is('.menu-toggle')){
            $(".nav").removeClass("nav--active");
            $(".menu-toggle").removeClass("menu-toggle--active");
        }
    });
    return false;
});

// Hidden menu when scroll

$(window).scroll(function() {
    $(".nav").removeClass("nav--active");
    $(".menu-toggle").removeClass("menu-toggle--active");
    $(".header").removeClass("header--animated");
});

// Magnific popup

if ($.fn.magnificPopup) {
    $('.open-popup').magnificPopup({
        type: 'inline',
        alignTop: true,
        midClick: true,
        fixedContentPos: true,
        removalDelay: 300,
        mainClass: 'modulos-popup fade-popup'
    });
    $.extend(true, $.magnificPopup.defaults, {
      tClose: 'Fechar (Esc)',
      tLoading: 'Carregando...'
    });
}

$(document).ready(function() {

    // When page is load scroll to top

    $(this).scrollTop(0);

    // Form

    if ($.fn.mask) {
        $("#telefone").mask("(99) 9999-9999?9");

        $("#telefone").on("blur", function() {
            var last = $(this).val().substr( $(this).val().indexOf("-") + 1 );

            if( last.length === 3 ) {
                var move = $(this).val().substr( $(this).val().indexOf("-") - 1, 1 );
                var lastfour = move + last;
                var first = $(this).val().substr( 0, 9 );

                $(this).val( first + '-' + lastfour );
            }
        });
    }

    // Validate form

    // (function() {
    //     if($.fn.validate) {
    //         var $contactForm = $('#form');
    //         $contactForm.validate();
    //         $contactForm.submit(function(e) {
    //             if ($(this).valid()) {
    //                 var nome = $("input#nome").val();
    //                 $(".subject").val(nome + " - Contato via site");
    //                 e.preventDefault();
    //                 $.ajax({
    //                     url: '/enviar-mensagem/',
    //                     method: 'POST',
    //                     data: $(this).serialize(),
    //                     dataType: 'json',
    //                     beforeSend: function() {
    //                         $(".submit").addClass("submit--loading");
    //                         $(".submit").val('Enviando...');
    //                     },
    //                     success: function(data) {
    //                         $(".submit").val('Enviado');
    //                         $(".submit").removeClass("submit--loading");
    //                         $(".submit").addClass("submit--success");
    //                         $contactForm.append('<p class="message">Obrigado por entrar em contato, retornaremos em breve.</p>');
    //                         $('input[type="submit"]').attr('disabled','disabled');
    //                         $('input[type="text"]').keyup(function() {
    //                             if($(this).val() !== '') {
    //                                 $('input[type="submit"]').removeAttr('disabled');
    //                             }
    //                         });
    //                     },
    //                     error: function(err) {
    //                         $(".submit").removeClass("submit--success");
    //                         $(".submit").addClass("submit--error");
    //                         $(".submit").val('Ops, ocorreu um erro');
    //                         $contactForm.append('<p class="message--error">Utilize nosso email abaixo pra enviar sua mensagem.</p>');
    //                         $('input[type="submit"]').attr('disabled','disabled');
    //                         $('input[type="text"]').keyup(function() {
    //                             if($(this).val() !== '') {
    //                                 $('input[type="submit"]').removeAttr('disabled');
    //                             }
    //                          });
    //                     }
    //                 });
    //             }
    //            return false;
    //         });
    //     }
    // })();

});
